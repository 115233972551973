@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

path {
  stroke: white;
  fill:paleturquoise !important;

  transition: fill .4s ease;
}

path:hover {
  fill: orange !important;
}

#us-map {
  display: block;
  /* position: absolute; */
  top: 0;
  left: 0;
  width: 50%;
  height: 50%;
}

#details-box {
  padding: 1rem;
  border-radius: 8px;
  font-size: 24px;
  position: fixed;
  color: white;
  font-family: "Poppins";
  background-color: black;
  width: fit-content;
  transform: translateX(-50%);
  transition: opacity .4s ease;
  z-index: 1;
}